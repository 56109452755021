<template>
    <div class="wrapper">
        <div class="top">
            <el-form :model="completeInformationForm" :rules="rules" ref="completeInformationForm">
                <el-form-item class="cu-form-item">
                    <span style="font-size: 18px; font-weight: bold;">{{$t('login.completeInformation')}}</span>
                </el-form-item>
                <el-form-item class="cu-form-item" prop="account" :label="title">
                    <el-input class="input" v-model="completeInformationForm.account" :disabled="true" :readonly="true" round/>
                </el-form-item>
                <el-form-item class="cu-form-item" prop="name" :label="$t('login.username')">
                    <el-row>
                        <el-col>
                            <el-input class="input" v-model="completeInformationForm.name" :placeholder="$t('login.name')" round/>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="cu-form-item" prop="password" :label="$t('login.password')">
                    <el-row>
                        <el-col>
                            <el-input class="input" v-model="completeInformationForm.password" :placeholder="$t('login.enterPin')" :show-password="true" round/>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="cu-form-item"  style="margin-top: 40px">
                    <el-button style="line-height: 10px;" @click="()=>{
                        this.$router.back();
                    }" round>{{$t('login.lastStep')}}</el-button>
                    <el-button style="width: 200px; line-height: 10px;"  type="primary" @click="register">{{$t('login.completeRegistrationEnterSuper')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {register} from "../../../request/api/merchant";
    import rules from "../js/rules";
    import CommonLogin from "../../../utils/CommonLogin";
    let Base64 = require('js-base64').Base64;
    export default {
        name: "CompleteInformation",
        data(){
            return {
                title: '',
                rules: rules.rules,
                completeInformationForm: {
                    account: "",
                    code: "",
                    name: "",
                    password: ""
                }
            }
        },
        mounted() {
            try {
                let val = this.$route.query.datum;
                if (val){
                    let decode = JSON.parse(Base64.decode(val));
                    if (decode){
                        if (decode.account && decode.code){
                            if (!decode.code.match("^\\d{5}$")){
                                this.$router.replace("/login/register");
                            }
                            if (decode.account.match("^(?:0|86|\\+86)?1[3-9]\\d{9}$")){
                                this.title =  this.$t('login.mobile');
                            }else if (decode.account.match("(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])")){
                                this.title =  this.$t('login.email');
                            }else {
                                this.$router.replace("/login/register");
                            }
                            this.completeInformationForm.account = decode.account;
                            this.completeInformationForm.code = decode.code;
                            return;
                        }
                    }
                }
            }catch (e) {
                this.$router.replace("/login/register");
            }
            this.$router.replace("/login/register");
        },
        methods: {
            register() {
                this.$refs["completeInformationForm"].validate((val) => {
                    if (val){
                        let _this = this;
                        let data = {
                            "account": _this.completeInformationForm.account,
                            "code": _this.completeInformationForm.code,
                            "name": _this.completeInformationForm.name,
                            "password": _this.completeInformationForm.password,
                        };
                        register(data).then((result) =>{
                            if (result && result.code === 200){
                                _this.login();
                            }else {
                                _this.$notify({
                                    title: _this.$t('login.hint'),
                                    message: result.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }else {
                        console.log("submit error!");
                        return false;
                    }
                });
            },
            login(){
                let data= {
                    'type':'simple',
                    'username': this.completeInformationForm.account,
                    'password': this.completeInformationForm.password,
                };
                let _this = this;
                CommonLogin.login(data, (result) =>{
                    if (result && result === true){
                        console.log(result);
                        _this.$router.replace("/enterpriseInformation");
                    }
                });
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    @input-width: 308px;

    .cu-form-item {
        margin-bottom: 10px;
    }

    .input {
        width: 308px;
        height: 32px;

        &/deep/ .el-input__inner{
            height: 32px;
        }
    }

    .wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .top {
            width: @input-width;
        }
    }
</style>
